@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';
@import 'styles/helpers.scss';

.btn {
  padding: 0.5em 1.5em;
  font-size: 16px;
  color: white;
  cursor: pointer;
  border-width: initial;
  border-style: none;
  border-image: initial;
  position: relative;
  outline: none;
  font-weight: 500;
  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid $primary-color;
  font-family: $font-family;
  font-weight: bold;
  &:disabled {
    opacity: 0.6;
    cursor: default;
  }
  &:hover:not(:disabled) {
    background-color: #e62404;
  }
  &:focus {
    // outline: none;
  }
  .btnContent {
    @extend .d-flex-center;
    width: 100%;
    .btnChildren:only-child {
      @extend .d-flex-center;
      min-height: 32px;
    }
    @include breakpoint-up(md) {
      width: 100%;
      max-height: 100%;
      height: 100%;
    }
  }
  .btnContentWithIcon {
    min-height: 34px;
    div:not(.btnChildren) {
      max-width: 50px;
    }
    div:first-child {
      margin-right: 5px;
    }
    // svg {
    //   margin-top: 5px;
    // }
  }
}

.btnLoading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.primary {
  background-color: $primary-color;
}

.danger {
  background-color: $primary-color;
  border-color: $primary-color;
  &:hover:not(:disabled) {
    background-color: darken($primary-color, 8%);
  }
}

.outline {
  border: 1px solid $primary-color;
  background-color: white;
  color: $primary-color;
  font-weight: bold;
  svg {
    margin-bottom: -3px;
    path {
      fill: $primary-color;
    }
  }
  &:hover:not(:disabled) {
    background-color: $primary-light-color;
  }
}

.monochrome {
  border: 1px solid white;
  background-color: white;
  color: #242020;
  font-weight: bold;
  svg {
    margin-bottom: -3px;
    path {
      fill: #242020;
    }
  }
  &:hover:not(:disabled) {
    background-color: lighten($color: #242020, $amount: 80);
    border: 1px solid lighten($color: #242020, $amount: 80)
  }
}