@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.imagesWrapper {
  position: relative;
  .logo {
    position: absolute;
    top: 24px;
    right: 24px;
  }
  .bg {
    width: 100%;
    border-radius: 16px;
  }
}

.row {
  margin-top: 100px;
  margin-bottom: 24px;
  .col {
    text-align: center;
  }
  @include breakpoint-up(md) {
    .col {
      text-align: left;
    }
  }
}