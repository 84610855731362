@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

@mixin fillSvg($color) {
  svg[class*='fill'] {
    path,
    line,
    circle {
      fill: $color;
    }
  }
  svg[class*='stroke'] {
    path,
    line,
    circle,
    rect {
      stroke: $color;
    }
  }
}

.button {
  padding: 0.7em 1.2em;
  font-size: 0.9em;
  margin-top: 10px;
  border-radius: 4px;
  color: $primary-color;
  flex: 1;
  font-weight: normal;
  min-width: 32%;
  pointer-events: all;
  border: 1px solid $font-color;
  @include breakpoint-up(md) {
    flex: 0;
    min-width: 30%;
  }
  @include breakpoint-up(xxl) {
    min-width: 25%;
  }
}

.notSelected {
  background-color: white;
  color: $dark-gray-color;
  @include fillSvg($dark-gray-color);
  &:hover:not(:disabled) {
    color: $primary-color;
    background-color: $primary-light-color;
  }
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  button:not(:first-child) {
    margin-left: 2px;
  }
  button:not(:last-child) {
    margin-right: 2px;
  }
}

.buttonsInOneRow {
  flex-wrap: nowrap;
}

.contentInColumn {
  div {
    margin: 0 !important;
    min-width: 100%;
  }
  & > div {
    display: flex;
    flex-direction: column;
    min-height: 75px !important;
    align-items: center;
    justify-content: space-between;
    & > div:not(:only-child) {
      &:first-child {
        margin-bottom: 5px !important;
      }
    }
  }
}

.buttonWithIcon {
  max-width: 130px;
  svg {
    max-height: 50px;
  }
  div div:last-child {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.dash {
  content: '';
  width: 45%;
  height: 3px;
  background-color: $primary-color;
  position: absolute;
  left: 30%;
  bottom: -3px;
  border-radius: 0px 0px 6px 6px;
}

.selected {
  background-color: white;
  color: $primary-color;
  @include fillSvg($primary-color);
  &:after {
    @extend .dash;
  }
  &:hover:not(:disabled) {
    color: $primary-color;
    background-color: $text-inverted-color;
  }
}

.note {
  margin-top: 16px;
  text-align: start;
}

.labelInTwoRows {
  padding-top: 50px !important;
  .label {
    top: 15px !important;
  }
}
