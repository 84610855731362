@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.text-right {
  text-align: right;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center;
}

.text-primary {
  color: $primary-color;
}

.text-black {
  color: $font-color;
}

.text-danger {
  color: $primary-color;
}

.text-warning {
  color: $yellow-color;
}

.text-blue {
  color: $blue-color;
}

.text-white {
  color: white !important;
}

.float-right {
  float: right;
}

.ml-auto {
  margin-left: auto;
}
.ml-4 {
  margin-left: ($spacer * 1) !important;
}
.ml-1 {
  margin-left: ($spacer * 0.25) !important;
}

.mt-0 {
  margin-top: 0;
}
.mt-md-0 {
  @include breakpoint-up(md) {
    margin-top: ($spacer * 1) !important;
  }
}
.mt-2 {
  margin-top: ($spacer * 0.5) !important;
}
.mt-4 {
  margin-top: ($spacer * 1) !important;
}
.mt-8 {
  margin-top: ($spacer * 2) !important;
}

.mb-0 {
  margin-bottom: 0;
}
.mb-2 {
  margin-bottom: ($spacer * 0.5) !important;
}
.mb-4 {
  margin-bottom: ($spacer * 1) !important;
}
.mb-8 {
  margin-bottom: ($spacer * 2) !important;
}

.mr-auto {
  margin-right: auto;
}
.mr-0 {
  margin-top: 0 !important;
}
.mr-1 {
  margin-right: ($spacer * 0.25) !important;
}
.mr-2 {
  margin-right: ($spacer * 0.5) !important;
}
.mr-4 {
  margin-right: ($spacer * 1) !important;
}

.m-0 {
  margin: 0;
}
.my-4 {
  margin-top: $spacer !important;
  margin-bottom: $spacer !important;
}
.mx-auto {
  margin-right: auto;
  margin-left: auto;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.p-2 {
  padding: ($spacer * 0.5) !important;
}

.pt-4 {
  padding-top: $spacer !important;
}

.pb-4 {
  padding-bottom: $spacer !important;
}

.py-0 {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.h-100 {
  height: 100%;
}

.bold {
  font-weight: bold;
}

.cursor-default {
  cursor: default !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.d-flex {
  display: flex;
}

.justify-content-end {
  justify-content: flex-end;
}

.d-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bg-white {
  background-color: white;
}

.position-relative {
  position: relative;
}
