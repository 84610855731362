@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.bodyOpen {
  overflow-y: hidden;
}

.primary {
  background-color: #f7f8f9 !important;
}

.overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 300ms ease-in-out;
  opacity: 0;
  z-index: $z-index-modal;
  padding: 0 20px 20px;
}

.overlayAfterOpen {
  opacity: 1;
}

.overlayBeforeClose {
  opacity: 0;
}

.modal {
  background: rgb(255, 255, 255);
  overflow: auto;
  border-radius: 0;
  outline: none;
  box-shadow: 0px 10px 40px rgba(51, 50, 92, 0.03);
  border-radius: 10px;
  position: relative;
  border: none;
  transition: opacity 300ms ease-in-out;
  opacity: 0;
  min-height: 200px;
  overflow: hidden;
  padding: 0 10px 30px 20px;
  max-width: 900px;
  width: 100%;
  margin-top: 0;
}

.modalAfterOpen {
  opacity: 1;
}

.modalBeforeClose {
  opacity: 0;
}

.modalHeading {
  text-align: center;
  margin-top: 0;
  padding: 0 24px;
}

.closeButtonWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  padding-top: 16px;
  padding-right: 12px;
}

.closeButton {
  border: none;
  cursor: pointer;
  height: 35px;
  width: 35px;
  border-radius: 8px;
  background-color: transparent;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    min-width: 28px;
    min-height: 28px;
    width: 100%;
    height: 100%;
  }
  &:hover {
    svg path {
      fill: black;
    }
  }
  &:active {
    outline: 5px solid $primary-light-color;
  }
}

.modalBody {
  overflow: auto;
  max-height: 70vh;
  margin-bottom: 15px;
  padding-right: 24px;
  max-width: 100%;
}
