@import 'styles/variables.scss';

.fieldWrapper {
  position: relative;
  padding-bottom: 20px;
  padding-top: 85px;
  margin-bottom: 5px;
  .sliderWrapper {
    padding: 0;
    .inputLabel {
      position: absolute;
      left: 0px;
      pointer-events: none;
      height: 32px;
      font-size: 18px;
      font-weight: 600;
      color: $font-color;
      top: 5px;
    }
    .slider {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      width: 100%;
      height: 8px;
      padding: 0;
      background-color: $light-gray-color;
      outline: none;
      -webkit-transition: 0.2s;
      transition: opacity 0.2s;
      position: relative;
      border-radius: 6px;
      padding: 0;
      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 30px;
        height: 30px;
        background-color: $primary-color;
        border-radius: 50%;
        border: 4px solid white;
        cursor: pointer;
      }
      &::-moz-range-thumb {
        width: 22px;
        height: 22px;
        background-color: $primary-color;
        border-radius: 50%;
        border: 4px solid white;
        cursor: pointer;
      }
    }
    .marks {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      color: #999;
      margin-top: 10px;
    }
  }
  .tooltip {
    min-width: 100px;
    max-width: 210px;
    border: 1px solid #f8bab4;
    background-color: white;
    border-radius: 5px;
    position: absolute;
    top: 30px;
    padding: 4px 5px 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.1s linear;
    .inputWrapper {
      position: relative;
      .numberInput {
        border-bottom: 1px solid $primary-color;
        padding: 5px 22px 5px 5px;
        width: 110px;
      }
      .units {
        position: absolute;
        right: 5px;
        top: 8px;
        font-size: 14px;
        color: $dark-gray-color;
      }
    }

    .triangle {
      content: '';
      position: absolute;
      transition: all 0.1s linear;
      top: 100%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: $medium-gray-color transparent transparent transparent;
    }
  }
}

.labelInTwoRows {
  padding-top: 110px;
  .tooltip {
    top: 55px;
  }
}
