@import 'styles/variables.scss';

.leftRounded {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.rightRounded {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}

.logo {
  background-color: white;
  padding: 24px 32px 16px;
  display: flex;
  flex-direction: column;
  width: fit-content;
  .text {
    font-size: 16px;
    color: $gray-color;
  }
  img {
    max-width: 100px;
  }
}