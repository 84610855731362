$primary-color: #ff0000;
$secondary-color: #a7a7a7;
$primary-light-color: lighten($primary-color, 45);
$font-color: #242020;
$yellow-color: #ffe000;
$success-color: #96c21f;
$light-gray-color: #B3B8B9;
$medium-gray-color: #707070;
$gray-color: #575757;
$dark-gray-color: #3c3c3c;
$blue-color: #0086cd;
$text-color: #000;
$text-inverted-color: #fff;
$background-color: #F4F4F4;

$font-family-heading: 'Sparkasse', sans-serif;
$font-family: 'Sparkasse', sans-serif;

$navbar-height: 72px;
$sidebar-width: 240px;
$footer-height: 35px;
$footer-mobile-height: 60px;

// Breakpoints
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1600px;

$z-index-loading-overlay: 10;
$z-index-navbar: 10;
$z-index-footer: 5;
$z-index-modal: 30;
$z-index-sidebar-overlay: 7;
$z-index-sidebar: 8;

$spacer: 16px;

// the :export directive is the magic sauce for webpack
:export {
  primaryColor: $primary-color;
  fontColor: $font-color;
  primaryLightColor: $primary-light-color;
  successColor: $success-color;
  yellowColor: $yellow-color;
  mediumGrayColor: $medium-gray-color;
  grayColor: $gray-color;
  darkGrayColor: $dark-gray-color;
  blueColor: $blue-color;
  backgroundColor: $background-color;
  navbarHeight: $navbar-height;
}
