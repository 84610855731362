@import 'styles/variables.scss';
@import 'styles/breakpoints.scss';

.imagesWrapper {
  position: relative;
  .bg {
    border-radius: 24px;
    width: 100%;
  }
  .logo {
    position: absolute;
    bottom: 20%;
    right: 0;
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
  }
}

.row {
  padding: 100px 0;
  margin-top: 100px;
  text-align: center;
  ul {
    text-align: left;
  }
  @include breakpoint-up(md) {
    text-align: left;
  }
}

.downloadPdfButton {
  margin-top: 40px;
}
